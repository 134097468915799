import { Alert, Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Api from "../network/api";
import '../styles/DatabasePage.css'; // Ensure this file has the necessary styles
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import { Plan } from "../models/plan";
import { Link } from "react-router-dom";


const DatabasePageLoggedOutView = () => {
    const [isTimedOut, setIsTimedOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTimedOut(true);
        }, 15000); // 10 seconds

        // Clean up the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="home-page" style={{display:"flex", height:'75vh', width:'100%', justifyContent:'center'}}>
            <div className={`main-content`}>
                {isTimedOut ? (
                    <div style={{ textAlign: 'center',  flexDirection:'column'}}>
                        <label style={{marginBottom:'12px', color: '#454545', fontSize:'1.5em',}}>Session timed out, please log back in</label>
                        <Link className='signup-button' to={'/'}>Back To Home</Link>
                    </div>

                ) : (
                    <Spinner text="Loading Database" />
                )}
            </div>
        </div>
    );
}

export default DatabasePageLoggedOutView;