import * as Api from "../network/api";
import { Condition, Medication, User } from '../models/user';
import '../styles/WorkoutBuilderPage.css';
import '../styles/SessionReview.css'
import { useState, useEffect, useRef } from "react";
import { Activity, ActivityStep, Day, Plan, Week } from "../models/plan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBan, faBarsProgress, faBed, faBicycle, faBorderNone, faCalendar, faCalendarCheck, faCross, faDumbbell, faHandSparkles, faNotdef, faPerson, faPlus, faRemove, faRunning, faSave, faStar, faStarAndCrescent, faSwimmer, faTrash } from "@fortawesome/free-solid-svg-icons";
import colorPalette from "../utils/colors";
import { Spinner } from "react-bootstrap";
// import exercises from "../localdata/exercises";
import extractActivityStep from "../utils/ActivityStepExtractor";
import { getSumTSS, getTSS } from "../utils/TSS";
import ChartComponent from "./ChartComponent";
import workoutBuilderGeneticAlgorithm, { GeneticAlgorithmOptions } from "../utils/WorkoutGenerator";
import { getTotalActivityLength, getTotalWeekLength } from "../utils/ActivityStats";
import BodyHighlighterDiagram from "./BodyHighlighter";
import { IExerciseData, Muscle } from "react-body-highlighter";
import { Exercise } from "../models/exercise";
import Select, { GroupBase } from 'react-select'
import { DBCondition, Symptom } from "../models/condition";

interface WorkoutBuilderPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onInfoSet: (user: User) => void,
}

interface SuggestionObject{
    suggestion:Exercise,
    suffix:string,
    triggers:Array<RegExp>
}

const days:Array<Day> = [
    {
        day:'Monday',
        activities:[]
    },
    {
        day:'Tuesday',
        activities:[]
    },
    {
        day:'Wednesday',
        activities:[]
    },
    {
        day:'Thursday',
        activities:[]
    },
    {
        day:'Friday',
        activities:[]
    },
    {
        day:'Saturday',
        activities:[]
    },
    {
        day:'Sunday',
        activities:[]
    }
]

const templatePlan: Plan = {
    planId: `plan_${Date.now()}`,
    title: 'Untitled Plan',
    description: '',
    baseLoad:100,
    weeklyAdjustmentValue:0.05,
    condition:'',
    content: [
        // {
        //     weekId:`week_${Math.random() * 10000}`,
        //     days: days,
        //     weekNumber: '1',
        //     weekTitle:`Week 1`,
        //     weekDescription:``
        // },
        // {
        //     weekId:`week_${Math.random() * 10000}`,
        //     days: days,
        //     weekNumber: '2',
        //     weekTitle:`Week 2`,
        //     weekDescription:``
        // },
        // {
        //     weekId:`week_${Math.random() * 10000}`,
        //     days: days,
        //     weekNumber: '3',
        //     weekTitle:`Week 3`,
        //     weekDescription:``
        // },
        // {
        //     weekId:`week_${Math.random() * 10000}`,
        //     days: days,
        //     weekNumber: '4',
        //     weekTitle:`Week 4`,
        //     weekDescription:``
        // },
    ],
    startDate: Date.now(),
};

const gaOptions:GeneticAlgorithmOptions = {
    populationSize: 50,
    generations: 100,
    mutationRate: 0.05,
    crossoverRate:0.8
};

export interface WorkoutOptions{
    type:string,
    distribution:Array<number>
}

type ActivityType = 'Run' | 'Cycle' | 'Swim' | 'Strength';

const workoutOptions = {
    "Run": [0.15, 0.7, 0.15],
    "Cycle": [0.2, 0.8],
    "Swim":[0.15, 0.7, 0.15],
    "Strength":[0.2, 0.2, 0.2, 0.2, 0.2]
};

interface SelectCondition {
    label: string;
    value: string;
}

// type OptionType = {
//     value: string;
//     label: string;
//   };
  
//   const options: OptionType[] = [
//     { value: "chocolate", label: "Chocolate" },
//     { value: "strawberry", label: "Strawberry" },
//     { value: "vanilla", label: "Vanilla" }
//   ];
  

const WorkoutBuilderPageLoggedInView = ({user, onLogoutSuccessful, onInfoSet}: WorkoutBuilderPageLoggedInProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<ActivityStep | null>(null);
    const [creatingPlan, setCreatingPlan] = useState(false);

    const [editingPlan, setEditingPlan] = useState<Plan | null>(null);
    const [editingWeek, setEditingWeek] = useState<Week | null>(null);
    const [editingDay, setEditingDay] = useState<Day | null>(null);
    const [editingActivity, setEditingActivity] = useState<Activity | null>(null);
    const [planLoading, setPlanLoading] = useState<Plan | null>(null);
    const [suggestionSearchInput, setSuggestionSearchInput] = useState('');
    const [useAI, setUseAI] = useState(true);

    const [stepInput, setStepInput] = useState('');
    const [titleInput, setTitleInput] = useState('');
    const [activityDescriptionInput, setActivityDescriptionInput] = useState('');
    const [baseLoadInput, setBaseLoadInput] = useState('');
    const [weeklyAdjustmentValueInput, setWeeklyAdjustmentValueInput] = useState('');

    const [selectedActivityOptions, setSelectedActivityOptions] = useState<Array<string>>(['Run']);

    const [descriptionInput, setDescriptionInput] = useState('');
    const [weekTitleInput, setWeekTitleInput] = useState('');
    const [weekDescriptionInput, setWeekDescriptionInput] = useState('');

    const [weeklyTrainingLoad, setWeeklyTrainingLoad] = useState(675);

    const [showDropdown, setShowDropdown] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [filteredSuggestions, setFilteredSuggestions] = useState<Array<SuggestionObject>>([]);
    const [suggestions, setSuggestions] = useState<Array<SuggestionObject>>([]);

    const [showBodyHighlighterPopup, setShowBodyHighlighterPopup] = useState(false);
    const [selectedStepExercise, setSelectedStepExercise] = useState('');
    const [dbExercises, setDBExercises] = useState<Exercise[]>([]);

    const [saving, setSaving] = useState(false);
    const [changesMade, setChangesMade] = useState(false);

    // const [selectedCondition, setSelectedCondition] = useState<Condition | null>(null);

    const [conditionOptions, setConditionOptions] = useState<Array<SelectCondition> | undefined>(undefined);
    const [conditions, setConditions] = useState<Array<DBCondition>>();

    const [selectedCondition, setSelectedCondition] = useState<SelectCondition | null>(null);
    // const [conditionOptions_, setConditionOptions] = useState<readonly SelectCondition[] | undefined>(undefined);

    // const conditionOptions = [
    //     { id: '0', label: 'Obesity' },
    //     { id: '1', label: 'Type 1 Diabetes' },
    //     { id: '2', label: 'Type 2 Diabetes' },
    //     { id: '3', label: 'Hypertension' },
    //     { id: '4', label: 'Hypercholesterolaemia' },
    // ];

    useEffect(() => {
        // Load Exercies
        getExercises();
        getConditions();

        return () => {
            console.log('Component will unmount');
    };

    }, []); // Empty dependency array ensures this runs once
  
    async function getExercises(){
        const response = await Api.getExercises().then(res=>{
            setDBExercises(res);
            const newSuggestions = [...suggestions];

            for (const exercise of res) {
                newSuggestions.push({
                    suggestion: exercise,
                    suffix: '',
                    triggers: [/(\d+\s+sets\s+of\s+\d+\s+reps\s+of\s+)$/, /(\d+\s+minutes\s+of\s+)$/, /(\d+\s+sets\s+of\s+\d+\s+(minutes|seconds)\s+of\s+)$/]
                });
            }
            
            setSuggestions(newSuggestions);
    
            console.log(res)
        });
    }

    async function getConditions() {
        try {
            const res = await Api.getConditions();
            setConditions(res);
            const newConditions = res.map(condition => ({
                label: condition.name,
                value: condition.id,
            }));
    
            // Set the mutable array in state
            setConditionOptions(newConditions);
        } catch (error) {
            console.error("Error fetching conditions: ", error);
        }
    }
    
    const handleInputChange = (e: any) => {
        const value = e.target.value;
        const cursorPos = e.target.selectionStart;
        setStepInput(value);
        setCursorPosition(cursorPos);
      
        // Check each suggestion's trigger regex array
        const matchingSuggestions = suggestions.filter((suggestion: SuggestionObject) =>
          suggestion.triggers.some((trigger: RegExp) => trigger.test(value.substring(0, cursorPos)))
        );
      
        if (matchingSuggestions.length > 0) {
          setFilteredSuggestions(matchingSuggestions);
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      };

    const handleDescriptionInputChange = (e: any) => {
        const value = e.target.value;
    
        if (editingPlan && editingWeek && editingDay && editingActivity) {
            // Find the index of the week
            const weekIndex = editingPlan.content.findIndex(
                week => week.weekNumber === editingWeek.weekNumber
            );
            if (weekIndex === -1) {
                console.error('Week not found');
                return;
            }
    
            // Find the index of the day
            const dayIndex = editingPlan.content[weekIndex].days.findIndex(
                day => day.day === editingDay.day
            );
            if (dayIndex === -1) {
                console.error('Day not found');
                return;
            }
    
            // Find the index of the activity
            const activityIndex = editingPlan.content[weekIndex].days[dayIndex].activities.findIndex(
                activity => activity.type === editingActivity.type
            );
            if (activityIndex === -1) {
                console.error('Activity not found');
                return;
            }
    
            // Update the description of the activity
            const updatedActivity = {
                ...editingPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                description: value, // Update the description
            };
    
            // Update the activities array with the updated activity
            const updatedActivities = [
                ...editingPlan.content[weekIndex].days[dayIndex].activities.slice(0, activityIndex),
                updatedActivity,
                ...editingPlan.content[weekIndex].days[dayIndex].activities.slice(activityIndex + 1),
            ];
    
            // Update the specific day with the updated activities
            const updatedDay = {
                ...editingPlan.content[weekIndex].days[dayIndex],
                activities: updatedActivities,
            };
    
            // Update the days array with the updated day
            const updatedDays = [
                ...editingPlan.content[weekIndex].days.slice(0, dayIndex),
                updatedDay,
                ...editingPlan.content[weekIndex].days.slice(dayIndex + 1),
            ];
    
            // Update the week with the updated days
            const updatedWeek = {
                ...editingPlan.content[weekIndex],
                days: updatedDays,
            };
    
            // Update the content array with the updated week
            const updatedContent = [
                ...editingPlan.content.slice(0, weekIndex),
                updatedWeek,
                ...editingPlan.content.slice(weekIndex + 1),
            ];
    
            // Create a new editingPlan object with the updated content
            const updatedPlan = {
                ...editingPlan,
                content: updatedContent,
            };
    
            // Update the state
            setEditingPlan(updatedPlan);
            setChangesMade(true)

    
        } else {
            console.error('Missing required state: editingPlan, editingWeek, or editingDay');
        }
        // Update the description input value
        setActivityDescriptionInput(value);
    };
      
    const handleSuggestionClick = (suggestion:SuggestionObject) => {
      if (cursorPosition !== null) {
        const newValue = 
          stepInput.slice(0, cursorPosition) + 
          suggestion.suggestion.name + 
          suggestion.suffix + 
          stepInput.slice(cursorPosition);
        setStepInput(newValue);
        setShowDropdown(false);
        setSelectedStepExercise(suggestion.suggestion.name)
        // setCursorPosition(null);
        if (inputRef.current){
            inputRef.current.focus();
        }
      }
    };

    const handleTitleChange = (newTitle:string) => {
        setTitleInput(newTitle)
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                title:newTitle,
            };
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }

    };
    const handleDescriptionChange = (newDesc:string) => {
        setDescriptionInput(newDesc)
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                description:newDesc,
            };
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }

    };
    const handleBaseLoadChange = (newBaseLoad:string) => {
        if (Number(newBaseLoad) || newBaseLoad.length === 0){
            const convertedInput = Number(newBaseLoad);
            setBaseLoadInput(newBaseLoad)
            if (editingPlan) {
                // Create a new editingPlan object to ensure immutability
                const updatedPlan = {
                    ...editingPlan,
                    baseLoad:convertedInput,
                };
                setEditingPlan(updatedPlan);
                setChangesMade(true)

            }
        }else{
            alert('Please only enter numbers')
        }
    };

    const handleWeeklyAdjustmentValueChange = (newWeeklyAdjustmentValue:string) => {
        const convertedInput = Number(newWeeklyAdjustmentValue);
        setWeeklyAdjustmentValueInput(newWeeklyAdjustmentValue)
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                weeklyAdjustmentValue:convertedInput,
            };
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }

    };

    const generateAiActivity = (type:string, options:any, weekIndex:number, dayIndex:number, numberOfActivities:number) => {
        var distribution = 
            [
                7,
                26,
                42,
                30,
                18,
                7,
                10
            ]
        const sumDistribution = distribution.reduce((partialSum, a) => partialSum + a, 0);
        for (var i = 0; i < distribution.length; i++){
            distribution[i] = distribution[i]/sumDistribution;
        }
        var targetTSS = 0;

        if (editingPlan){
            // Example usage
            const targetWeeklyTrainingLoad = editingPlan.baseLoad + (weekIndex * editingPlan.baseLoad*editingPlan.weeklyAdjustmentValue);

            if (dayIndex >= 0){
                targetTSS = distribution[dayIndex] * targetWeeklyTrainingLoad;
            }
        }
        
        var activity:Activity;
        activity = workoutBuilderGeneticAlgorithm(targetTSS/numberOfActivities, options, type, user, workoutOptions[type as ActivityType]);
        // for (var j = 0;j<activity.steps.length;j++){
        //     let step = activity.steps[j]
        //     activity.steps[j] = extractActivityStep(step.stepDescription, step.stepName)
        // }
        return activity;
    };
    
    const addWeek = () => {        
        var days:Array<Day> = [
            {
                day:'Monday',
                activities:[],
            },
            {
                day:'Tuesday',
                activities:[]
            },
            {
                day:'Wednesday',
                activities:[]
            },
            {
                day:'Thursday',
                activities:[]
            },
            {
                day:'Friday',
                activities:[]
            },
            {
                day:'Saturday',
                activities:[]
            },
            {
                day:'Sunday',
                activities:[]
            }
        ]
        if (editingPlan && useAI){
            for (var day of days){
                // Ensure selectedActivityOptions is not empty to avoid errors
                if (selectedActivityOptions.length === 0) {
                    throw new Error("selectedActivityOptions cannot be empty.");
                }

                const numberOfActivities = Math.floor(Math.random() * selectedActivityOptions.length) + 1;

                // Create a set to keep track of selected indices to ensure uniqueness
                const selectedIndices = new Set();
                
                // Call generateAiActivity with the random value
                for (let i = 0; i < numberOfActivities; i++) {
                    let randomIndex;
                
                    // Ensure we get a unique random index
                    do {
                        randomIndex = Math.floor(Math.random() * selectedActivityOptions.length);
                    } while (selectedIndices.has(randomIndex));
                
                    // Add the selected index to the set
                    selectedIndices.add(randomIndex);
                
                    // Select a random value from the array using the unique index
                    let randomValue = selectedActivityOptions[randomIndex];
                
                
                    const AIActivity = generateAiActivity(randomValue, gaOptions, (editingPlan.content.length + 1), days.indexOf(day), numberOfActivities);
                    if (AIActivity.steps.length > 0) {
                        day.activities.push(AIActivity);
                    }
                }
                
            }
        }
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                content: [
                    ...editingPlan.content,
                    {
                        days: days,
                        weekId:`week_${Math.random() * 10000}`,
                        weekNumber: (editingPlan.content.length + 1).toString(),
                        weekTitle:`Week ${(editingPlan.content.length + 1).toString()}`,
                        weekDescription:``
                    },
                ],
            };

            setEditingPlan(updatedPlan);
            setChangesMade(true)

        } else {
            // Define a new editingPlan object with the initial values
            const newPlan: Plan = {
                _id: '',
                planId: '',
                title: '',
                description: '',
                baseLoad:100,
                weeklyAdjustmentValue:0.05,
                condition:'',
                content: [
                    // {
                    //     weekId:`week_${Math.random() * 10000}`,
                    //     days: days,
                    //     weekNumber: '1',
                    //     weekTitle:`Week 1`,
                    //     weekDescription:``
                        
                    // },
                    // {
                    //     weekId:`week_${Math.random() * 10000}`,
                    //     days: days,
                    //     weekNumber: '2',
                    //     weekTitle:`Week 2`,
                    //     weekDescription:``
                    // },
                    // {
                    //     weekId:`week_${Math.random() * 10000}`,
                    //     days: days,
                    //     weekNumber: '3',
                    //     weekTitle:`Week 3`,
                    //     weekDescription:``
                    // },
                    // {
                    //     weekId:`week_${Math.random() * 10000}`,
                    //     days: days,
                    //     weekNumber: '4',
                    //     weekTitle:`Week 4`,
                    //     weekDescription:``
                    // },
                ],
                startDate: Date.now(),
            };
            setEditingPlan(newPlan);
            setChangesMade(true)
            
        }
    };

    const addActivity = (type:string) => {
        var distribution = 
            [
                7,
                26,
                42,
                30,
                18,
                7,
                10
            ]
        const sumDistribution = distribution.reduce((partialSum, a) => partialSum + a, 0);
        for (var i = 0; i < distribution.length; i++){
            distribution[i] = distribution[i]/sumDistribution;
        }
        // Example usage
        var targetTSS = 100;
        if (editingPlan && editingWeek && editingDay){
            const weekIndex = editingPlan.content.findIndex(w=>w.weekId===editingWeek.weekId);
            const targetWeeklyTrainingLoad = editingPlan.baseLoad + (weekIndex * editingPlan.baseLoad*editingPlan.weeklyAdjustmentValue);

            const index = editingWeek.days.findIndex(d=>d.day===editingDay.day);
            if (index >= 0){
                targetTSS = distribution[index] * targetWeeklyTrainingLoad;
            }
        }
        var activity:Activity;
        console.log('log: ' + distribution)
        if (useAI && type!=='Rest'){
            activity = workoutBuilderGeneticAlgorithm(targetTSS, gaOptions, type, user, workoutOptions[type as ActivityType]);
            // for (var j = 0;j<activity.steps.length;j++){
            //     let step = activity.steps[j]
            //     activity.steps[j] = extractActivityStep(step.stepDescription, step.stepName)
            // }
        }

        else{
            // Define the activity to be added
            activity = {
                id:`workout_${Date.now()}`,
                title: `${type} Activity`,
                type: type,
                description: "",
                duration: 20,
                workout: null,
                steps:[]
            };

        }

    
        // Ensure that all necessary states are defined
        if (editingPlan && editingWeek && editingDay) {
            // Find the index of the week to update
            const weekIndex = editingPlan.content.findIndex(week => week.weekNumber === editingWeek.weekNumber);
            if (weekIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
    
            // Find the index of the day to update
            const dayIndex = editingPlan.content[weekIndex].days.findIndex(day => day.day === editingDay.day);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Get the current week and its days
            const updatedWeek = editingPlan.content[weekIndex];
            const updatedDays = [...updatedWeek.days];
    
            // Ensure the activities array is initialized
            if (!updatedDays[dayIndex].activities) {
                updatedDays[dayIndex].activities = [];
            }
    
            // Add the activity to the specified day's activities
            const updatedDay = {
                ...updatedDays[dayIndex],
                activities: [...updatedDays[dayIndex].activities, activity],
            };
    
            // Replace the updated day in the days array
            updatedDays[dayIndex] = updatedDay;
    
            // Create the new content array with the updated week
            const updatedContent = [...editingPlan.content];
            updatedContent[weekIndex] = {
                ...updatedWeek,
                days: updatedDays,
            };
    
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                content: updatedContent,
            };
    
            // Update the state
            setEditingPlan(updatedPlan);
            setChangesMade(true)


        } else {
            console.error('Missing required state: editingPlan, editingWeek, or editingDay');
        }
    };
    
    const deleteWeek = (weekToDelete: Week) => {
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                content: editingPlan.content.filter(week => week.weekId !== weekToDelete.weekId),
            };
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }
    };
    
    const deleteActivity = (activityToDelete: Activity) => {
        if (editingPlan && editingWeek && editingDay) {
            // Find the index of the current week and day
            const weekIndex =editingPlan.content.findIndex(w=>w.weekNumber ===  editingWeek.weekNumber);
            const dayIndex = editingWeek.days.findIndex(day => day.day === editingDay.day);
    
            // Filter out the activity to delete
            const updatedActivities = editingPlan.content[weekIndex].days[dayIndex].activities.filter(activity => activity.id !== activityToDelete.id);
    
            // Create a new day object with the updated activities
            const updatedDay = {
                ...editingPlan.content[weekIndex].days[dayIndex],
                activities: updatedActivities,
            };
    
            // Create a new week object with the updated day
            const updatedWeek = {
                ...editingPlan.content[weekIndex],
                days: [
                    ...editingPlan.content[weekIndex].days.slice(0, dayIndex),
                    updatedDay,
                    ...editingPlan.content[weekIndex].days.slice(dayIndex + 1),
                ],
            };
    
            // Create a new editingPlan object with the updated week
            const updatedPlan = {
                ...editingPlan,
                content: [
                    ...editingPlan.content.slice(0, weekIndex),
                    updatedWeek,
                    ...editingPlan.content.slice(weekIndex + 1),
                ],
            };
    
            // Set the updated editingPlan
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }
    };

    async function addStep (stringInput:string){
        var newStep:ActivityStep = extractActivityStep(stringInput, `step_${Date.now()}`);
        // newStep.stepExercise = selectedStepExercise;
        if (editingPlan && editingWeek && editingDay && editingActivity) {
            const weekIndex = editingPlan.content.findIndex(week => week.weekNumber === editingWeek.weekNumber);
            if (weekIndex === -1) {
                console.error('Week not found');
                return;
            }
    
            // Find the index of the day to update
            const dayIndex = editingPlan.content[weekIndex].days.findIndex(day => day.day === editingDay.day);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Get the current week and its days
            const updatedWeek = editingPlan.content[weekIndex];
            const updatedDays = [...updatedWeek.days];
    
            // Find the activity to update
            const activityIndex = updatedDays[dayIndex].activities.findIndex(activity => activity.type === editingActivity.type);
            if (activityIndex === -1) {
                console.error('Activity not found');
                return; // Activity not found
            }
    
            // Add the new step to the specified activity
            const updatedActivity = {
                ...updatedDays[dayIndex].activities[activityIndex],
                steps: [...(updatedDays[dayIndex].activities[activityIndex].steps || []), newStep],
            };
    
            // Replace the updated activity in the activities array
            const updatedActivities = [
                ...updatedDays[dayIndex].activities.slice(0, activityIndex),
                updatedActivity,
                ...updatedDays[dayIndex].activities.slice(activityIndex + 1),
            ];
    
            // Replace the updated day in the days array
            const updatedDay = {
                ...updatedDays[dayIndex],
                activities: updatedActivities,
            };
    
            // Replace the updated day in the days array
            updatedDays[dayIndex] = updatedDay;
    
            // Create the new content array with the updated week
            const updatedContent = [...editingPlan.content];
            updatedContent[weekIndex] = {
                ...updatedWeek,
                days: updatedDays,
            };
    
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                content: updatedContent,
            };

            console.log(updatedActivity)
    
            // Update the state
            setEditingPlan(updatedPlan);
            setChangesMade(true)

            setEditingActivity(updatedActivity)
    
        } else {
            console.error('Missing required state: editingPlan, editingWeek, or editingDay');
        }
        // });

    };
    
    const showPopup = (step: ActivityStep) => {
        setCurrentStep(step);
        setPopupVisible(true);
      };

    const findIndices = () => {
        if (!editingPlan || !editingWeek || !editingDay || !editingActivity) return;

        const weekIndex = editingPlan.content.findIndex(
        week => week.weekNumber === editingWeek.weekNumber
        );
        if (weekIndex === -1) {
        console.error('Week not found');
        return; // Week not found
        }

        const dayIndex = editingPlan.content[weekIndex].days.findIndex(
        day => day.day === editingDay.day
        );
        if (dayIndex === -1) {
        console.error('Day not found');
        return; // Day not found
        }

        const activityIndex = editingPlan.content[weekIndex].days[dayIndex].activities.findIndex(
        activity => activity.title === editingActivity.title
        );
        if (activityIndex === -1) {
        console.error('Activity not found');
        return; // Activity not found
        }

        return { weekIndex, dayIndex, activityIndex };
    };

    const removeStep = () => {
        if (!editingPlan || !currentStep) return;

        const indices = findIndices();
        if (!indices) return;

        const { weekIndex, dayIndex, activityIndex } = indices;

        const week = editingPlan.content[weekIndex];
        const day = week.days[dayIndex];
        const activity = day.activities[activityIndex];

        const updatedSteps = activity.steps.filter(step => step.stepName !== currentStep.stepName);

        const updatedActivity = { ...activity, steps: updatedSteps };
        const updatedActivities = [
        ...day.activities.slice(0, activityIndex),
        updatedActivity,
        ...day.activities.slice(activityIndex + 1),
        ];

        const updatedDay = { ...day, activities: updatedActivities };
        const updatedWeeks = [
        ...week.days.slice(0, dayIndex),
        updatedDay,
        ...week.days.slice(dayIndex + 1),
        ];

        const updatedPlan = {
        ...editingPlan,
        content: [
            ...editingPlan.content.slice(0, weekIndex),
            { ...week, days: updatedWeeks },
            ...editingPlan.content.slice(weekIndex + 1),
        ],
        };

        setEditingPlan(updatedPlan);
        setChangesMade(true)

        setEditingActivity(updatedActivity)
        setPopupVisible(false);
    };

    const shiftLeft = () => {
        if (!editingPlan || !currentStep) return;

        const indices = findIndices();
        if (!indices) return;

        const { weekIndex, dayIndex, activityIndex } = indices;

        const week = editingPlan.content[weekIndex];
        const day = week.days[dayIndex];
        const activity = day.activities[activityIndex];

        const index = activity.steps.findIndex(step => step.stepName === currentStep.stepName);
        if (index > 0) {
        const newSteps = [...activity.steps];
        [newSteps[index - 1], newSteps[index]] = [newSteps[index], newSteps[index - 1]];

        const updatedActivity = { ...activity, steps: newSteps };
        const updatedActivities = [
            ...day.activities.slice(0, activityIndex),
            updatedActivity,
            ...day.activities.slice(activityIndex + 1),
        ];

        const updatedDay = { ...day, activities: updatedActivities };
        const updatedWeeks = [
            ...week.days.slice(0, dayIndex),
            updatedDay,
            ...week.days.slice(dayIndex + 1),
        ];

        const updatedPlan = {
            ...editingPlan,
            content: [
            ...editingPlan.content.slice(0, weekIndex),
            { ...week, days: updatedWeeks },
            ...editingPlan.content.slice(weekIndex + 1),
            ],
        };

        setEditingPlan(updatedPlan);
        setChangesMade(true)

        setEditingActivity(updatedActivity)

        //   setPopupVisible(false);
        }
    };

    const shiftRight = () => {
        if (!editingPlan || !currentStep) return;

        const indices = findIndices();
        if (!indices) return;

        const { weekIndex, dayIndex, activityIndex } = indices;

        const week = editingPlan.content[weekIndex];
        const day = week.days[dayIndex];
        const activity = day.activities[activityIndex];

        const index = activity.steps.findIndex(step => step.stepName === currentStep.stepName);
        if (index < activity.steps.length - 1) {
        const newSteps = [...activity.steps];
        [newSteps[index + 1], newSteps[index]] = [newSteps[index], newSteps[index + 1]];

        const updatedActivity = { ...activity, steps: newSteps };
        const updatedActivities = [
            ...day.activities.slice(0, activityIndex),
            updatedActivity,
            ...day.activities.slice(activityIndex + 1),
        ];

        const updatedDay = { ...day, activities: updatedActivities };
        const updatedWeeks = [
            ...week.days.slice(0, dayIndex),
            updatedDay,
            ...week.days.slice(dayIndex + 1),
        ];

        const updatedPlan = {
            ...editingPlan,
            content: [
            ...editingPlan.content.slice(0, weekIndex),
            { ...week, days: updatedWeeks },
            ...editingPlan.content.slice(weekIndex + 1),
            ],
        };

        setEditingPlan(updatedPlan);
        setChangesMade(true)

        setEditingActivity(updatedActivity)

        //   setPopupVisible(false);
        }
    };

    function getWeeklyTrainingLoad(week:Week){
        var total = 0;
        for (const day of week.days){
            for (const activity of day.activities){
                total += getSumTSS(activity, user);
                console.log(activity)
            }
        }
        return total;
    }

    async function uploadNewPlan() {
        var newPlan = {...templatePlan};
        newPlan.planId = `plan_${Date.now()}`;
        if (!creatingPlan){
            setCreatingPlan(true)
            try {
    
                // Clone the user object to avoid direct mutation
                const newUser = { ...user };
    
                const newEditablePlans = newUser.editablePlans;
                newEditablePlans.push(newPlan)
                
                newUser.editablePlans = newEditablePlans;
            
                // Update the user data via API
                await Api.updateUser(user._id, newUser);
                onInfoSet(newUser);
                setCreatingPlan(false)
    
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }

    }

    async function uploadPlanToLibrary(plan:Plan) {
        try{
            // Uplaod the plan data via API
            await Api.createPlan(plan).then(res=>{
                alert("Plan Published To Library!");
            })
        }catch (e:any){
            alert('Something Went Wrong')
        }

    }

    async function deletePlan(planToDelete: Plan) {
        if (!planLoading){
            setPlanLoading(planToDelete)

            try {
                // Clone the user object to avoid direct mutation
                const newUser = { ...user };
        
                // Create a new array without the plan to be deleted
                const newEditablePlans = newUser.editablePlans.filter(p => p.planId !== planToDelete.planId);
                
                // Assign the filtered array to newUser's editablePlans
                newUser.editablePlans = newEditablePlans;
            
                // Update the user data via API
                await Api.updateUser(user._id, newUser);
                onInfoSet(newUser);
                setPlanLoading(null)
    
                
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }

    async function savePlanChanges() {
        setSaving(true)
        try {    
            // Reset the editing state
            if (editingActivity) {
                setEditingActivity(null);
            } else if (editingDay) {
                setEditingDay(null);
            } else if (editingWeek) {
                setEditingWeek(null);
            } else {
                setEditingPlan(null);
            }
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };

            // Find the plan in editablePlans and update it
            const planIndex = newUser.editablePlans.findIndex(p => p.planId === editingPlan?.planId);
            if (planIndex !== -1 && editingPlan) {
                newUser.editablePlans[planIndex] = editingPlan;
            }
            // Update the user data via API
            if (changesMade){
                await Api.updateUser(user._id, newUser);
                setSaving(false)
                setChangesMade(false)
            }

        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setSaving(false)
    }
    
    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            addStep(stepInput)
            // Perform any action you want to trigger on Enter key press here
        }
    };

    function getMuscleData(activity: Activity) {
        const data: IExerciseData[] = [];
    
        // for (const step of activity.steps) {
        //     console.log(step.stepExercise)
        //     if (step.stepExercise){
        //         const stepExerciseData = exercises.find(e => e.name === step.stepExercise);
        //         if (stepExerciseData && stepExerciseData.primaryMuscles.length > 0) {
        //             const stepMuscles = stepExerciseData.primaryMuscles.map(muscle => {
        //                 // Assuming Muscle is a string or you can map it to the appropriate type
        //                 return muscle as Muscle;
        //             });
        
        //             data.push({
        //                 name: step.stepExercise || 'Unknown Exercise', // Providing a fallback string in case stepExercise is undefined
        //                 muscles: stepMuscles || [],
        //             });
        //         }
        //     }

        // }
        // console.log(data)
        return data;
    }

    function updateSelectedActivityOptions(option: string) {
        // Create a new array based on the current state
        const newOptions = [...selectedActivityOptions];
    
        if (newOptions.includes(option)) {
            // Remove the option if it exists
            const index = newOptions.indexOf(option);
            if (index !== -1) {
                newOptions.splice(index, 1);
            }
        } else {
            // Add the option if it does not exist
            newOptions.push(option);
        }
    
        // Update the state with the new options
        setSelectedActivityOptions(newOptions);
    }

    function symptomIsBlacklisted(symptom:Symptom){
        if (conditions && editingPlan){
            const findCondition = conditions.find(c=>c.name===editingPlan.condition);
            if (findCondition){
                console.log(findCondition.name)
                for (const s of findCondition.symptoms){
                    if (s.description === symptom.description){
                        return true
                    }
                }

            }

        }
        return false
    }

    const handleConditionChange = (selectedOption: any) => {
        setSelectedCondition(selectedOption);
      
        // Update currentPlan's condition
        if (editingPlan) {
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...editingPlan,
                condition:selectedOption.label,
            };
            setEditingPlan(updatedPlan);
            setChangesMade(true)

        }
        // savePlanChanges();
    };
      
    return (
        <div className="page" style={{display:'flex', pointerEvents:saving?'none':'all', height:'auto', flex:1, justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'center', overflowY:'scroll'}}>
            {saving && (
                    
                <div style={{position:'absolute', right:'2vh', bottom:'3vh', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label style={{color:'red', fontWeight:'bold', marginRight:'10px', fontSize:'20px'}}>Saving</label>
                <FontAwesomeIcon icon={faSave} style={{ color: 'red',width:'40px', height:'40px'}}></FontAwesomeIcon>

                </div>
            )}
            {editingPlan && 
                <div style={{display:'flex', width:'100%', justifyContent:'flex-start'}}>
                    <FontAwesomeIcon className="back-arrow" style={{width:'30px', height:'30px', color:'#454545'}} icon={faArrowLeft} onClick={()=>{savePlanChanges()}}></FontAwesomeIcon>
                </div>
            } 
            {editingPlan === null && 
                <div className="plans-page">
                    <h2 className="title" style={{fontStyle:'italic', color:colorPalette.steelBlue, fontSize:'3vw', fontWeight:'normal'}}><b>VITOVA</b> WORKOUT BUILDER</h2>
                    <div style={{ overflowY: 'auto', maxHeight: '65vh', padding:'10px'}}>
                    <ul className="grid-container" style={{ listStyleType: 'none', padding: 0 }}>
                        {user.editablePlans && user.editablePlans.map((plan:Plan, index:number) => (
                            <li key={index} className="grid-item" style={{flexDirection:'column'}}>
                                <div onClick={()=>{if(planLoading === plan){}else{setEditingPlan(plan);setTitleInput(plan.title);setDescriptionInput(plan.description); setBaseLoadInput(plan.baseLoad.toString()); setWeeklyAdjustmentValueInput(plan.weeklyAdjustmentValue.toString())}}} className='card' style={{position: 'relative', borderRadius:'5px',backgroundColor: `${colorPalette.steelBlue}`, }}
                                >
                                    <h2 className="plan-card-title" style={{color:'white'}}>{plan.title}</h2>
                                    {planLoading === plan ? 
                                        <Spinner className="trash-icon" style={{width:'30px', height:'30px', color:'white'}} ></Spinner>
                                        :
                                        <FontAwesomeIcon className="trash-icon" style={{color:'white'}} onClick={(e)=>{deletePlan(plan);e.stopPropagation()}} icon={faRemove} />
                                    }
                                    <label onClick={(e)=>{uploadPlanToLibrary(plan); e.stopPropagation();}} className="publish-button" style={{backgroundColor:'#f1f1f1', borderRadius:'5px',color:colorPalette.steelBlue, marginTop:'10px'}}>Publish To Library</label>
                                </div>

                            </li>
                        ))}
                            <li className="grid-item" style={{width:'20vw'}}>
                                <div className="card" style={{backgroundColor:colorPalette.steelBlue, opacity:'0.75'}} onClick={()=>uploadNewPlan()}>
                                {/* {creatingPlan ? 
                                        <Spinner  style={{width:'50px', height:'50px', color:'white'}} ></Spinner>
                                        : */}
                                        <FontAwesomeIcon icon={faPlus} style={{color:'white', width:'50px', height:'50px'}}></FontAwesomeIcon>
                                    {/* } */}
                                </div>
                            </li>

                    </ul>
                    </div>
                </div>
            }
            {/* PLAN VIEW */}
            {editingPlan && !editingWeek && !editingDay &&
            <div>
                <label className="title" style={{ fontSize: '1.5em', marginBottom:0,}}>{`Plan Details`}</label>

                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'row', width:'90%', justifyContent:'center', alignItems:'center'}}>
                    
                    <input onChange={(e)=>{handleTitleChange(e.currentTarget.value);}} value={titleInput} className="details-input-title" style={{height:'50px'}} placeholder="Plan title"></input>
                    {conditionOptions && 
                        <Select
                        className="exercise-info-input"
                        placeholder="Condition"
                        name="condition"
                        value={conditionOptions.find(c=>c.label === editingPlan.condition)}
                        onChange={handleConditionChange}
                        styles={{
                            control: (base) => ({
                              ...base,
                              height: '50px',
                              borderRadius: '4px',
                              marginBottom:'10px',
                              border: '1px solid #ccc',
                            }),
                          }}
                        options={conditionOptions}
                        isClearable
                        />
                    }
                    </div>

                    <textarea
                        onChange={(e) => { handleDescriptionChange(e.currentTarget.value); }}
                        value={descriptionInput}
                        className="details-input-desc"
                        placeholder="Plan description"
                        rows={4}
                        style={{ resize: "none", overflowY: "auto" }}
                    ></textarea> 
                    <input onChange={(e)=>{handleBaseLoadChange(e.currentTarget.value);}} value={baseLoadInput} className="details-input-title" style={{marginTop:'10px'}} placeholder="Base weekly training load"></input>
                    <input onChange={(e)=>{handleWeeklyAdjustmentValueChange(e.currentTarget.value);}} value={weeklyAdjustmentValueInput} className="details-input-title" style={{marginTop:'10px'}} placeholder="Weelkly adjustment value (e.g 0.05 = 5% increase in training load per week)"></input>

                    </div>
                

                <ul className="grid-container">
                {editingPlan.content.map((week:Week, index) => {
                    return (
                    <li key={index} className="grid-item">
                <div 
                className='card' 
                onClick={() => {
                    setEditingWeek(week);
                    setWeekTitleInput(week.weekTitle);
                    setWeekDescriptionInput(week.weekDescription);
                }} 
                style={{ borderRadius: '0' }}
                >
                <FontAwesomeIcon 
                    className="card-img" 
                    style={{ color: week.days.every((day: any) => day.activities.length > 0) ? colorPalette.steelBlue : '#777777', borderRadius: '0' }} 
                    icon={week.days.every((day: any) => day.activities.length > 0) ? faCalendarCheck : faCalendar} 
                />
                <h2 className="card-title" style={{ color: week.days.every((day: any) => day.activities.length > 0) ? colorPalette.steelBlue : '#777777' }}>
                    {`Week ${editingPlan.content.indexOf(week) + 1}`}
                </h2>
                <FontAwesomeIcon 
                    className="trash-icon" 
                    onClick={(e) => { deleteWeek(week); e.stopPropagation(); }} 
                    icon={faRemove} 
                />
                </div>
                    </li>
                    )})}
                    <li className="grid-item">

                        <div className='card' style={{borderRadius:'0', backgroundColor:colorPalette.steelBlue, flexDirection:'column', justifyContent:'flex-start'}}>
                            <div style={{display:'flex', width:'100%', alignItems:'flex-end', justifyContent:'flex-end'}}>
                                <FontAwesomeIcon onClick={(e)=>{setUseAI(!useAI);e.stopPropagation();}} className="use-ai-icon" style={{color:useAI?'gold':'white'}}  icon={faHandSparkles}></FontAwesomeIcon>
                            </div>
                            <div onClick={() => addWeek()} style={{display:'flex', width:'100%', height:'100%',alignItems:'center', justifyContent:'center'}}>
                                <FontAwesomeIcon className="card-img" style={{color: 'white',borderRadius:'0', marginBottom:'50px'}} icon={faPlus} />
                            </div>
                            <div className="icon-container" style={{marginBottom:'10px'}}>
                                <div style={{backgroundColor:selectedActivityOptions.includes('Run') ? 'gold':'white'}} className="icon-square" onClick={(e)=>{updateSelectedActivityOptions('Run');e.stopPropagation()}}>
                                    <FontAwesomeIcon className="icon" icon={faRunning} />
                                </div>
                                <div style={{backgroundColor:selectedActivityOptions.includes('Cycle') ? 'gold':'white'}} className="icon-square" onClick={(e)=>{updateSelectedActivityOptions('Cycle');e.stopPropagation()}}>
                                    <FontAwesomeIcon className="icon" icon={faBicycle} />
                                </div>
                                <div style={{backgroundColor:selectedActivityOptions.includes('Swim') ? 'gold':'white'}} className="icon-square" onClick={(e)=>{updateSelectedActivityOptions('Swim');e.stopPropagation()}}>
                                    <FontAwesomeIcon className="icon" icon={faSwimmer} />
                                </div>
                                <div style={{backgroundColor:selectedActivityOptions.includes('Strength') ? 'gold':'white'}} className="icon-square" onClick={(e)=>{updateSelectedActivityOptions('Strength');e.stopPropagation()}}>
                                    <FontAwesomeIcon className="icon" icon={faDumbbell} />
                                </div>
                            </div>
                            {/* <h2 className="card-title" style={{color:'white'}}>{`Add Another Week`}</h2> */}
                        </div>
                    </li>
            </ul>
            </div>

            }

            {/* WEEK VIEW */}
            {editingPlan && editingWeek && !editingDay && 
                <ul className="grid-container">
                    <li key={1} className="grid-item" >
                            <div onClick={()=>setEditingDay({day:'Overview', activities:[]})} className='card' style={{backgroundColor:colorPalette.steelBlue, borderRadius:'0'}} >
                                <FontAwesomeIcon className="card-img" style={{color:'#ffffff', borderRadius:'0'}} icon={faBarsProgress} />
                                <h2 className="card-title" style={{color:'#ffffff'}}>Overview</h2>
                            </div>
                        </li>
                {editingPlan.content[editingPlan.content.findIndex(w=>w.weekNumber ===  editingWeek.weekNumber)].days.map((day, index) => {
                    return (
                    <li key={index} className="grid-item">
                        <div className='card' onClick={() => setEditingDay(day)} style={{borderRadius:'0'}}>
                            <FontAwesomeIcon className="card-img" style={{color: day.activities.length > 0 ? colorPalette.steelBlue:'#777777',borderRadius:'0'}} icon={day.activities.length <= 0 ? faPerson : day.activities[0].type === 'Rest' ? faBed : day.activities[0].type === 'Run' ? faRunning: day.activities[0].type === 'Swim' ? faSwimmer: day.activities[0].type === 'Cycle' ? faBicycle: day.activities[0].type === 'Strength' ? faDumbbell : faPerson} />
                            <h2 className="card-title" style={{color:day.activities.length > 0 ? colorPalette.steelBlue:'#777777'}}>{day.day}</h2>
                        </div>
                    </li>
                    )})}
            </ul>
            }

            {/* DAY VIEW */}
            {editingPlan && editingWeek && editingDay && !editingActivity && editingDay.day === 'Overview' &&
                <div className="stage-div" style={{justifyContent:'flex-start', alignItems:'flex-start', marginTop:'10px', backgroundColor:'#f1f1f1', height:'70vh'}}>
                    <div style={{display:'flex', width:'100%', height:'100%', flexDirection:'column', alignItems:'flex-start'}}>
                        
                        <label style={{color:'#454545', marginTop: '2vh', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>{`Weekly Statistics`}</label>
                        <label style={{color:'#565656', marginLeft:'1vw', fontSize:'1.25vw', fontWeight:'bold'}}>{`Target Training Load - ${Math.round(editingPlan.baseLoad + (editingPlan.content.findIndex(w=>w.weekId===editingWeek.weekId) * editingPlan.baseLoad*editingPlan.weeklyAdjustmentValue))} | Actual Training Load - ${Math.round(getWeeklyTrainingLoad(editingWeek))}`}</label>
                        <label style={{color:'#565656', marginLeft:'1vw', fontSize:'1.25vw', fontWeight:'bold'}}>{`${getTotalWeekLength(editingWeek)}`}</label>
                        <label style={{color:'#454545', marginTop: '3vh', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>Training Load Distribution</label>
                        <ChartComponent weekData={editingPlan.content[editingPlan.content.findIndex(w=>w.weekNumber ===  editingWeek.weekNumber)].days} mh={55} mw={60} user={user}></ChartComponent>
                    </div>
                    
                </div>    
            }
            {editingPlan && editingWeek && editingDay && !editingActivity && editingDay.day !== 'Overview' &&
                <div className="stage-div" style={{justifyContent:'flex-start', alignItems:'flex-start', marginTop:'40px'}}>
                <ul className="grid-container">

                {editingPlan.content[editingPlan.content.findIndex(w=>w.weekNumber ===  editingWeek.weekNumber)].days[editingWeek.days.findIndex(day=>day.day === editingDay.day)].activities.map((activity:Activity, index)=>(
                    <li key={index} className="grid-item" onClick={()=>{setEditingActivity(activity); setActivityDescriptionInput(activity.description)}}>
                        <div className='card' style={{borderRadius:'0', position: 'relative'}}>
                            <FontAwesomeIcon className="card-img" style={{color: '#777777',borderRadius:'0'}} icon={activity.type ==='Rest' ? faBed : activity.type === 'Run' ? faRunning : activity.type === 'Swim' ? faSwimmer : activity.type === 'Cycle' ? faBicycle : activity.type === 'Strength' ? faDumbbell : faPerson} />
                            <h2 className="card-title" style={{color:'#777777'}}>{`${activity.type} Activity`}</h2>
                            <FontAwesomeIcon className="trash-icon" onClick={(e)=>{deleteActivity(activity);e.stopPropagation()}} icon={faRemove} />
                        </div>
                    </li>

                ))}
                <li className="grid-item">
                    <div className='card' style={{borderRadius:'0', backgroundColor:colorPalette.steelBlue, justifyContent:'flex-start',position: 'relative', width:'22vw'}}>
                        <div style={{width:'100%', display:'flex', height:'auto', justifyContent:'flex-end', marginTop:'10px'}} onClick={()=>setUseAI(!useAI)}>
                            <label style={{marginTop:'3px', marginRight:'5px', color:useAI?'gold':'white', fontWeight:'bold'}}>{useAI?'AI Enabled':'Use AI'}</label>
                            <FontAwesomeIcon  className="use-ai-icon" style={{color:useAI?'gold':'white'}}  icon={faHandSparkles}></FontAwesomeIcon>
                        </div>
                        <div style={{width:'100%', display:'flex', height:'68%', justifyContent:'center', alignItems:'center'}}>
                        <h2 className="card-title" style={{color:'white'}}>{`Add Activity`}</h2>
                            
                        </div>

                        <div className="icon-container">
                            <div className="icon-square" onClick={()=>addActivity('Run')}>
                                <FontAwesomeIcon className="icon" icon={faRunning} />
                            </div>
                            <div className="icon-square" onClick={()=>addActivity('Cycle')}>
                                <FontAwesomeIcon className="icon" icon={faBicycle} />
                            </div>
                            <div className="icon-square" onClick={()=>addActivity('Swim')}>
                                <FontAwesomeIcon className="icon" icon={faSwimmer} />
                            </div>
                            <div className="icon-square" onClick={()=>addActivity('Strength')}>
                                <FontAwesomeIcon className="icon" icon={faDumbbell} />
                            </div>
                            <div className="icon-square" onClick={()=>addActivity('Rest')}>
                                <FontAwesomeIcon className="icon" icon={faBed} />
                            </div>
                        </div>
                    </div>
                </li>

        </ul>
                </div>
        
            }
            {/* ACTIVITY VIEW */}
            {editingPlan && editingWeek && editingDay && editingActivity && 
                <div className="stage-div" style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '40px' }}>
                        <input 
                                style={{marginBottom:'10px', width:'100%', padding:'10px 20px'}}
                                value={activityDescriptionInput}
                                onChange={handleDescriptionInputChange} 
                                className="input" 
                                placeholder="Activity Description"
                                type="text" 
                                />
                    <div className="addStepContainer">

                        <div className="flex-start">
                            <input 
                            value={stepInput} 
                            onChange={handleInputChange} 
                            className="input" 
                            placeholder="e.g 5 minutes of running at intensity 6"
                            type="text" 
                            onKeyDown={handleKeyPress}
                            ref={inputRef}
                            />
                            {showDropdown && (
                            <ul className="dropdown">
                                <input value={suggestionSearchInput} onChange={(e)=>setSuggestionSearchInput(e.currentTarget.value)} placeholder="search for an exercise" style={{width:'100%', padding:'5px 10px'}}></input>
                                {filteredSuggestions.filter(suggestion=>suggestion.suggestion.name.toLowerCase().includes(suggestionSearchInput.toLowerCase())).slice(0,10).map((suggestionObj:SuggestionObject, index) => (
                                    <li 
                                        style={{backgroundColor:index % 2 === 0 ? `${colorPalette.steelBlue}33`:`${colorPalette.steelBlue}66`}}
                                        key={index} 
                                        onClick={() => handleSuggestionClick(suggestionObj)} 
                                        className="dropdown-item"
                                    >
                                        <label style={{fontSize:'1.25vw', fontWeight:'bold'}}>
                                        {suggestionObj.suggestion.name}
                                        </label>

                                        {/* Display the conditionsBlackList as horizontal tags */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '4px', justifyContent:'center', alignItems:'center'}}>
                                        {conditions && editingPlan?.condition && 
                                            suggestionObj.suggestion.symptomBlackList
                                                .map((symptom, idx) => (
                                                    <span 
                                                        key={idx} 
                                                        style={{ 
                                                            display:'flex',
                                                            justifyContent:'center',
                                                            alignItems:'center',
                                                            border: `2px solid ${symptomIsBlacklisted(symptom) ? colorPalette.crimson : 'white'}`,
                                                            color:symptomIsBlacklisted(symptom) ? colorPalette.crimson:'#f1f1f1',
                                                            borderRadius: '4px', 
                                                            padding: '2px 6px', 
                                                            backgroundColor: symptomIsBlacklisted(symptom) ? '#ffe6e6': `${colorPalette.steelBlue}33`
                                                        }}
                                                    >
                                                        <label>{symptom.description}</label>
                                                        
                                                    </span>
                                                ))
                                        }


                                        </div>
                                        {suggestionObj.suggestion.images.length > 0 &&
                                            <div style={{ display: 'flex', gap: '10px', marginTop: '15px', width: '70vw', overflow: 'hidden' }}>
  {/* Images container */}
  <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '25vw' }}>
    {suggestionObj.suggestion.images.slice(0, 2).map((img, index) => (
      <img
        key={index}
        style={{ width: '100%', height: 'auto', marginBottom: '10px', borderRadius: '5px' }}
        src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${img}`}
        alt={suggestionObj.suggestion.name}
      />
    ))}
  </div>

  {/* Instructions container */}
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      textAlign: 'left',
      paddingLeft: '10px',
      maxWidth: '70%', // Ensure instructions fit within the view
      wordWrap: 'break-word', // Wrap long words
      overflow: 'hidden', // Prevent overflow
    }}
  >
    {suggestionObj.suggestion.instructions.map((instruction, index) => (
      <label key={index} style={{ marginBottom: '10px', fontSize: '1em' }}>
        {`${index + 1}. ${instruction}`}
      </label>
    ))}
  </div>
</div>

                                        }
                                    </li>

                                ))}
                            </ul>
                            )}
                        </div>
                        <div className="flex-end">
                            <label 
                            onClick={() => addStep(stepInput)} 
                            className="add-step-button"
                            >
                            Add Step
                            </label>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', width:'100%', height:'60vh'}}>
                        
                    <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                            borderColor: '#ffffff',
                            borderStyle: 'solid',
                            borderTopWidth:'0',
                            overflowX:'hidden',
                            maxWidth:'80vw',
                            // maxHeight:'45vh',
                            // minHeight:'45vh',
                            marginTop:'10vh',
                            borderRightWidth:'0',
                            width:'95%',
                            // maxWidth:'50vw',
                            padding:'5px 5px 0px 0px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                        {editingActivity.steps.map((step:ActivityStep, index) => (
                            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                            {popupVisible && currentStep === step && (
                                <div className="popup">
                                    <FontAwesomeIcon className="popup-option" icon={faArrowLeft} onClick={shiftLeft}></FontAwesomeIcon>
                                    <FontAwesomeIcon className="popup-option" icon={faRemove} onClick={removeStep}></FontAwesomeIcon>
                                    <FontAwesomeIcon className="popup-option" icon={faArrowRight} onClick={shiftRight}></FontAwesomeIcon>
                                </div>
                            )}
                            {step.stepSets && step.stepSets > 0? 
                                <div key={`step-${index}`} 
                                    onClick={()=>{if (currentStep === step){setCurrentStep(null);}else{showPopup(step)}}}
                                    className="tooltip"
                                    style={{
                                        backgroundColor:colorPalette.steelBlue,
                                        opacity:1,
                                        width:`calc(${step.stepSets}*2vw)`, 
                                        maxWidth:`calc(50vw/${editingActivity.steps.length})`,
                                        height: step.stepReps && step.stepReps > 0 ? `calc(${step.stepReps}*1vw)` : step.stepDuration && step.stepDuration > 0 ? `calc(${step.stepDuration/60*step.stepSets}*1vw)` : '1vw',
                                        marginRight:'2px',
                                        justifyContent:'flex-start', 
                                        display:'flex', 
                                        alignContent:'flex-start'
                                    }}>
                                    <span className="tooltiptext">{step.stepDescription}</span>
                                </div>
                            :
                            <div key={`step-${index}`} 
                                onClick={()=>{if (currentStep === step){setCurrentStep(null);}else{showPopup(step)}}}
                                className="tooltip"
                                style={{
                                    backgroundColor:colorPalette.steelBlue,
                                    opacity:1,
                                    width:step.stepDuration && step.stepDuration > -1 ? `calc(${step.stepDuration/800}*8vw)`: step.stepDistance && step.stepDistance > -1 ? `calc(${step.stepDistance/5}* 8vw)`:'2vw', 
                                    height: step.stepIntensity && step.stepIntensity > 0 ? `calc(${step.stepIntensity} * 2vh` : step.stepZone && step.stepZone > 0 ? `calc(${step.stepZone} * 4vh` : '1vh',
                                    marginRight:'2px',
                                    maxWidth:`calc(50vw/${editingActivity.steps.length})`,
                                    justifyContent:'flex-start', 
                                    display:'flex', 
                                    alignContent:'flex-start'
                                }}>
                                <span className="tooltiptext">{step.stepDescription}</span>
            
                            </div>
                            }
                            </div>
                            )
                        )}
                        </div>    
                    </div>
                    <div style={{ flexDirection: 'column', display:'flex', flex:1,justifyContent:'flex-start', alignItems:'flex-start', backgroundColor:'#f1f1f1', padding:'10px', overflowY:'scroll'}}>
                    <label style={{textAlign:'left', fontWeight:'bold', fontSize:'1.25em',}}>Summary</label>
                    <label style={{textAlign:'left'}}>{`Total TSS - ${getSumTSS(editingActivity, user)}`}</label>
                    {editingActivity.type !== 'Strength' && 
                    <label style={{textAlign:'left'}}>{`${getTotalActivityLength(editingActivity)}`}</label>
                    }

                    <label style={{textAlign:'left', fontWeight:'bold', fontSize:'1.25em', marginTop:'10px'}}>Workout Steps</label>
                    {editingActivity.steps.map((step: ActivityStep, index) => (
                    <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px',justifyContent: 'space-between', alignItems: 'center' }}>
                        <label style={{ textAlign: 'left', fontSize: '0.9em', flexGrow: 1 }}>
                        {`${index + 1}. ${step.stepDescription}`}
                        </label>
                        <label style={{ textAlign: 'right', fontSize: '0.7em', fontWeight: 'bold', }}>
                        {`(${getTSS(step, user)} TSS)`}
                        </label>
                    </div>
                    ))}
                    {editingActivity.type === "Strength" && 
                    <div style={{display:'flex', width:'100%', flexDirection:'column',marginTop:'10px'}}>
                        <label style={{textAlign:'left', fontWeight:'bold', fontSize:'1.25em'}}>Anatomy</label>
                        <div style={{display:'flex',  width:'100%', height:'40vh', justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <BodyHighlighterDiagram data={getMuscleData(editingActivity)} width={100} height={100}/>
                        </div>
                    </div>
                    }
                    </div>
                    </div>

            </div>
            }
        </div>
    );
}

export default WorkoutBuilderPageLoggedInView;
