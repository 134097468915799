import React from 'react';
import Slider from "react-slick";
import jakePhoto from '../jake.jpg';
import nickPhoto from '../nickPhoto.jpg';
import '../styles/AboutPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SocialIcon } from 'react-social-icons'
import colorPalette from '../utils/colors';
import img1 from '../exmouthcycle.jpg'
import img3 from '../exmouthcycle.jpg'
import img2 from '../trailrun.jpeg'


const StoryPage: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            overflowY: 'scroll',
            scrollSnapType: 'y mandatory'
          }}>            
            {/* OUR STORY */}
            <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '80vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '100%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '0',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to left, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>OUR STORY</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>The journey and vision</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    marginTop: '20px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    <b>VITOVA</b> has been built around the idea that a smart, <b>medically informed</b> approach to training should be available to all. Allowing you to have <b>confidence</b> in the sessions that you’re doing, and lead a healthy active lifestyle in the process. Our plans are built around the <b>latest evidence</b> to ensure that you can make improvements both in your fitness, and the control of any relevant medical conditions.
                    </p>
                </div>             
            </div>
            
        </div>

    );
}

export default StoryPage;
