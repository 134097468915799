import React from 'react';
import Slider from "react-slick";
import jakePhoto from '../jake.jpg';
import nickPhoto from '../nickPhoto.jpg';
import '../styles/AboutPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SocialIcon } from 'react-social-icons'
import colorPalette from '../utils/colors';

const teamMembers = [
    {
        name: 'Jake Keast',
        role:'Founder / Head Coach',
        description: 'An elite triathlete, coach and final year medicine student at the University Of Exeter. I take huge pride in understanding the balance between staying healthy and pushing your limits when balancing all aspects of life.'
,       photo: jakePhoto,
        instagram:'the.jk.journey'
    },
    {
        name: 'Nicholas Latham',
        role: 'Lead Developer',
        description:'A high performance athlete and Computer Science graduate from the University Of Exeter. Experience developing Machine Learning models and Optimisation Algorithms.',
        photo: nickPhoto,
        instagram:'nickandbeans'
    },
];

const NextArrow = (props:any) => {
    const { onClick } = props;
    return (
        <div className="arrow next" style={{backgroundColor:'transparent'}} onClick={onClick}>
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    );
}

const PrevArrow = (props:any) => {
    const { onClick } = props;
    return (
        <div className="arrow prev" style={{backgroundColor:'transparent'}} onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
        </div>
    );
}

const AboutPage: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            overflowY: 'scroll',
            // scrollSnapType: 'y mandatory'
          }}>    
            {/* <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '80vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '100%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '0',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to left, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>OUR BACKGROUND</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'30px',

                    }}>The Problem</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    marginTop: '10px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    Over 50% of the population are troubled by the conditions that we are able to offer more specialist advice to support. Over 25% of the UK population has metabolic syndrome - encompassing having any of high blood pressure, high cholesterol, insulin resistance, and abdominal obesity. Consider this alongside the recent consensus that almost 1 in 3 people report high levels of anxiety or depression and we start to see a pattern where informed lifestyle support offers a really valuable benefit.
                    </p>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>Our Drive</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    marginTop: '10px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    Each of the conditions that we support has been shown to have clinically significant improvements from leading a healthy active lifestyle. Whether it be direct mechanical benefits of losing weight, improved physiological markers, or a more positive mindset, our plans take your feedback and adapt to make sure that your plan works your individual needs.                    
                    </p>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>Why Us?</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    marginTop: '10px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    Having been a personal trainer and endurance sports performance coach for the over 10 years, we saw an opportunity to share this knowledge to a wider audience. As a final year medical student, GP exercise referral instructor, and more specialist obesity and weight management instructor, we wanted to create a platform that met the needs of the population.                    
                    </p>
                </div>             
            </div>        */}
            <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '40vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '100%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '0',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to left, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>OUR STORY</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>The journey and vision</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    marginTop: '20px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    <b>VITOVA</b> has been built around the idea that a smart, <b>medically informed</b> approach to training should be available to all. Allowing you to have <b>confidence</b> in the sessions that you’re doing, and lead a healthy active lifestyle in the process. Our plans are built around the <b>latest evidence</b> to ensure that you can make improvements both in your fitness, and the control of any relevant medical conditions.
                    </p>
                </div>             
            </div>
          {/* MEET THE TEAM */}
            <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '80vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '40%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '0',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to right, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>The Team</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    margin: 0
                    }}>Meet Our Members</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    padding: '20px',
                    textAlign: 'justify',
                    width: '80%' // Adjust width as needed
                    }}>
                    At <b>VITOVA</b>, our expert team combines cutting-edge technology, health expertise, and athletic coaching. Our technical developers design innovative solutions, our <b>medical professionals</b> provide comprehensive care, and our professional coaches enhance performance with tailored training. Together, we deliver a <b>holistic</b> approach to excellence in health and fitness.
                    </p>
                    <label 
                                style={{
                                    fontSize: '1em',
                                    color: '#454545',
                                    textAlign: 'center',
                                    lineHeight:1,
                                    width: 'auto',
                                    margin:0,
                                    padding:'10px 20px',
                                }}>
                                    Interested in a career at VITOVA?</label>
                    <label 
                    className='review-button'
                    style={{
                        fontSize: '1em',
                        textAlign: 'center',
                        lineHeight: 1,
                        width: 'auto',
                        cursor: 'pointer' // Added cursor for better UX
                    }}
                    onClick={() => window.location.href = 'mailto:contactvitovaltd@gmail.com?subject=Career%20Enquiry'}
                    >
                    Enquire About Open Positions
                    </label>
                </div>

                <div style={{
                    display: 'flex',
                    width: '60%', // Adjusted to share space with title-div
                    height: '100%',
                    // background: 'linear-gradient(to right, #f3f3f3, #ffffff)',
                    flexDirection: 'column', // Ensure vertical stacking within slider container
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom:'5vh',
                    paddingRight:'5vw',
                    overflow: 'visible',
                    zIndex: 100
                }}>
                    <div className="meet-the-team-container" style={{  width: '100%' }}>
                    <Slider {...settings}>
                        {teamMembers.map((member, index) => (
                        <div key={index} className="team-member" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <img src={member.photo} alt={member.name} style={{ width: '20vw', height: '20vw', objectFit: 'cover', borderRadius: '50%' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '3vh' }}>
                            <label style={{ fontSize: '2vw', fontWeight: 'bold', paddingRight: 0 }}>{member.name}</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SocialIcon bgColor='transparent' fgColor='steelBlue' style={{ width: '3vw', height: '3vw' }} url={`https://www.instagram.com/${member.instagram}/`} />
                                <label style={{ fontSize: '1.5vw', margin: '0 6px' }}>|</label>
                                <label style={{ fontSize: '1.5vw' }}>{member.role}</label>
                            </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '1vh' }}>
                            <label style={{ fontSize: '1.1vw', textAlign: 'center', maxWidth: '50%' }}>{member.description}</label>
                            </div>
                        </div>
                        ))}
                    </Slider>
                    </div>
                </div>              
            </div>
        </div>

    );
}

export default AboutPage;
