import { Activity, ActivityStep, Day, Plan, Week } from '../models/plan';
import { getTSS } from './TSS';
import { CheckedCondition, CheckedMedication, Condition, Medication, User } from '../models/user';
import exercises from "../localdata/exercises";

// Genetic Algorithm Options Interface
export interface GeneticAlgorithmOptions {
    populationSize: number;
    mutationRate: number;
    generations: number;
    crossoverRate:number;
}

const options:GeneticAlgorithmOptions = {
    populationSize: 25,
    generations: 200,
    mutationRate: 0.1,
    crossoverRate:0.8
};

export type ActivityType = 'Run' | 'Cycle' | 'Swim' | 'Strength';

export const workoutOptions = {
    "Run": [0.15, 0.7, 0.15],
    "Cycle": [0.2, 0.8],
    "Swim":[0.15, 0.7, 0.15],
    "Strength":[0.2, 0.2, 0.2, 0.2, 0.2]
};
// Function to get the verb from the activity type
function getVerbFromType(type: string) {
    switch (type) {
        case 'Run':
            return 'running';
        case 'Cycle':
            return 'cycling';
        case 'Swim':
            return 'swimming';
        case 'Strength':
            return 'weightlifting';
        default:
            return 'light stretching';
    }
}

// Function to create a random ActivityStep
function createRandomActivityStep(type: string): ActivityStep {
    let stepExercise;
    let stepSets;
    let stepReps;
    let stepZone;
    let stepDistance;
    let randomId;

    switch (type) {
        case 'Cycle':
            // Ensure stepDistance is rounded to the nearest 0.5
            stepDistance = Math.round((Math.random() * 50 + 10) / 5) * 5;
            stepZone = Math.floor(Math.random() * 4) + 1;
            randomId = Math.floor(Math.random() * 1000000000) + 1;

            return {
                stepName: `step_${randomId}`,
                stepType: type,
                stepDistance: stepDistance,
                stepZone: stepZone,
                stepDescription: `${stepDistance} km of ${getVerbFromType(type)} at zone ${stepZone}`
            };
        case 'Run':
            // Ensure stepDistance is rounded to the nearest 0.5
            stepDistance = Math.round((Math.random() * 6 + 0.5) * 2) / 2;
            stepZone = Math.floor(Math.random() * 4) + 1;
            randomId = Math.floor(Math.random() * 1000000000) + 1;

            return {
                stepName: `step_${randomId}`,
                stepType: type,
                stepDistance: stepDistance,
                stepZone: stepZone,
                stepDescription: `${stepDistance} km of ${getVerbFromType(type)} at zone ${stepZone}`
            };
        case 'Swim':
            stepDistance = Math.round((Math.random() * (3 - 0.1) + 0.1) * 20) / 20;
            stepZone = Math.floor(Math.random() * 4) + 1;
            randomId = Math.floor(Math.random() * 1000000000) + 1;

            return {
                stepName: `step_${randomId}`,
                stepType: type,
                stepDistance: stepDistance,
                stepZone: stepZone,
                stepDescription: `${stepDistance} km of ${getVerbFromType(type)} at zone ${stepZone}`
            };
        default:
            const strengthExercises = exercises.filter(e => e.category === 'strength' && (e.primaryMuscles.includes('quadriceps') || e.primaryMuscles.includes('calves')));
            stepSets = Math.floor(Math.random() * (5 - 1) + 1);
            stepReps = Math.floor(Math.random() * (15 - 3) + 3);
            stepZone = Math.floor(Math.random() * 4) + 1;
            const randomExerciseIndex = Math.floor(Math.random() * strengthExercises.length - 1) + 1;
            stepExercise = strengthExercises[randomExerciseIndex]?.name;
            randomId = Math.floor(Math.random() * 1000000000) + 1;

            return {
                stepName: `step_${randomId}`,
                stepType: type,
                stepSets: stepSets,
                stepReps: stepReps,
                stepExercise: stepExercise,
                stepZone: stepZone,
                stepDescription: `${stepSets} sets of ${stepReps} reps of ${stepExercise} at zone ${stepZone}`
            };
    }
}

function evolveStep(targetStepTSS: number, options: GeneticAlgorithmOptions, type: string, user: User): ActivityStep {
    // Step 1: Initialize the population
    let population: ActivityStep[] = createInitialPopulation(options.populationSize, type);

    for (let generation = 0; generation < options.generations; generation++) {
        // Step 2: Evaluate fitness of each individual in the population
        let fitnessScores = population.map(step => calculateFitness(step, targetStepTSS, user));

        // Step 3: Selection - choose individuals for the next generation
        let matingPool = selectMatingPool(population, fitnessScores, options.crossoverRate);

        // Step 4: Crossover - produce new individuals by combining pairs from the mating pool
        let offspring = crossoverPopulation(matingPool, options.crossoverRate);

        // Step 5: Mutation - apply random changes to some offspring
        offspring = mutatePopulation(offspring, options.mutationRate);

        // Step 6: Create new population
        population = offspring;

        // Step 7: Check if any individual meets the target TSS
        let bestStep = population.length > 0 
            ? population.reduce((best, current) =>
                calculateFitness(current, targetStepTSS, user) < calculateFitness(best, targetStepTSS, user) ? current : best
            )
            : null; // Handle the case where population is empty

        if (bestStep && Math.abs(getTSS(bestStep, user) - targetStepTSS) < 0.1 && bestStep.stepDistance) {
            // Target TSS achieved, return this step
            bestStep.stepDescription = `${bestStep.stepDistance} km of ${getVerbFromType(bestStep.stepType)} at zone ${bestStep.stepZone}`;
            return bestStep;
        }
    }

    // If max generations reached, return the best candidate
    let finalBestStep = population.length > 0 
        ? population.reduce((best, current) =>
            calculateFitness(current, targetStepTSS, user) < calculateFitness(best, targetStepTSS, user) ? current : best
        )
        : null; // Handle the case where population is empty

    if (finalBestStep && finalBestStep.stepDistance) {
        finalBestStep.stepDescription = `${finalBestStep.stepDistance} km of ${getVerbFromType(finalBestStep.stepType)} at zone ${finalBestStep.stepZone}`;
    }

    // If finalBestStep is null, we may want to handle this case separately
    return finalBestStep || {stepName:'Default Step',stepDistance: 0, stepType: type, stepZone: 1, stepDescription: 'No valid step found' };
}


// Helper functions:

function createInitialPopulation(size: number, type: string): ActivityStep[] {
    let population: ActivityStep[] = [];
    for (let i = 0; i < size; i++) {
        population.push(createRandomActivityStep(type));
    }
    return population;
}

function calculateFitness(step: ActivityStep, targetTSS: number, user: User): number {
    return Math.abs(getTSS(step, user) - targetTSS);
}

function selectMatingPool(population: ActivityStep[], fitnessScores: number[], crossoverRate: number): ActivityStep[] {
    // Simple selection: roulette wheel selection or tournament selection
    let matingPool: ActivityStep[] = [];
    let totalFitness = fitnessScores.reduce((total, score) => total + (1 / score), 0);
    for (let i = 0; i < population.length; i++) {
        let randomValue = Math.random() * totalFitness;
        let cumulativeFitness = 0;
        for (let j = 0; j < population.length; j++) {
            cumulativeFitness += (1 / fitnessScores[j]);
            if (cumulativeFitness >= randomValue) {
                matingPool.push(population[j]);
                break;
            }
        }
    }
    return matingPool;
}

function crossoverPopulation(matingPool: ActivityStep[], crossoverRate: number): ActivityStep[] {
    let offspring: ActivityStep[] = [];
    for (let i = 0; i < matingPool.length; i += 2) {
        if (Math.random() < crossoverRate && i + 1 < matingPool.length) {
            let parent1 = matingPool[i];
            let parent2 = matingPool[i + 1];
            // Simple one-point crossover
            let crossoverPoint = Math.random();
            if (parent1.stepDistance && parent1.stepDistance > 0 && parent2.stepDistance && parent2.stepDistance > 0) {
                // Ensure new distances are rounded to the nearest 0.5
                const d1 = Math.round((crossoverPoint * parent1.stepDistance + (1 - crossoverPoint) * parent2.stepDistance) * 2) / 2;
                const d2 = Math.round((crossoverPoint * parent2.stepDistance + (1 - crossoverPoint) * parent1.stepDistance) * 2) / 2;
                offspring.push({
                    stepDistance: d1,
                    stepZone: parent1.stepZone,
                    stepType: parent1.stepType,
                    stepDescription:`${d1} km of ${getVerbFromType(parent1.stepType)} at zone ${parent1.stepZone}`,
                    stepName:parent1.stepName,
                });
                offspring.push({
                    stepDistance: d2,
                    stepZone: parent2.stepZone,
                    stepType: parent2.stepType,
                    stepDescription:`${d2} km of ${getVerbFromType(parent2.stepType)} at zone ${parent2.stepZone}`,
                    stepName:parent2.stepName
                });
            }else{
                if (parent1.stepSets && parent1.stepReps && parent2.stepSets && parent2.stepReps) {
                    // Perform crossover for sets and reps
                    const sets1 = Math.round(crossoverPoint * parent1.stepSets + (1 - crossoverPoint) * parent2.stepSets);
                    const sets2 = Math.round(crossoverPoint * parent2.stepSets + (1 - crossoverPoint) * parent1.stepSets);
                    const reps1 = Math.round(crossoverPoint * parent1.stepReps + (1 - crossoverPoint) * parent2.stepReps);
                    const reps2 = Math.round(crossoverPoint * parent2.stepReps + (1 - crossoverPoint) * parent1.stepReps);
                
                    offspring.push({
                        stepSets: sets1,
                        stepReps: reps1,
                        stepZone: parent1.stepZone,
                        stepType: parent1.stepType,
                        stepDescription: `${sets1} sets of ${reps1} reps of ${parent1.stepExercise} at zone ${parent1.stepZone}`,
                        stepExercise:parent1.stepExercise,
                        stepName: parent1.stepName,
                    });
                    
                    offspring.push({
                        stepSets: sets2,
                        stepReps: reps2,
                        stepZone: parent2.stepZone,
                        stepType: parent2.stepType,
                        stepDescription: `${sets2} sets of ${reps2} reps of ${parent2.stepExercise} at zone ${parent2.stepZone}`,
                        stepExercise:parent2.stepExercise,
                        stepName: parent2.stepName,
                    });
                }
                
            }

        } else {
            // If no crossover, just add the parents to the offspring
            offspring.push(matingPool[i]);
            if (i + 1 < matingPool.length) offspring.push(matingPool[i + 1]);
        }
    }
    return offspring;
}

function mutatePopulation(population: ActivityStep[], mutationRate: number): ActivityStep[] {
    return population.map(individual => {
        // Mutate stepDistance if it exists
        if (Math.random() < mutationRate && individual.stepDistance) {
            // Mutate distance slightly and round to the nearest 0.5
            individual.stepDistance = Math.round((individual.stepDistance + (Math.random() - 0.5) * 0.2) * 2) / 2;
        }

        // Mutate stepSets if it exists
        if (Math.random() < mutationRate && individual.stepSets) {
            // Mutate sets slightly and round to the nearest whole number
            individual.stepSets = Math.max(1, Math.round(individual.stepSets + (Math.random() - 0.5) * 2));
        }

        // Mutate stepReps if it exists
        if (Math.random() < mutationRate && individual.stepReps) {
            // Mutate reps slightly and round to the nearest whole number
            individual.stepReps = Math.max(1, Math.round(individual.stepReps + (Math.random() - 0.5) * 2));
        }

        return individual;
    });
}


// Main function to generate an activity with optimized steps
export function workoutBuilderGeneticAlgorithmPerStep(targetTSS: number, options: GeneticAlgorithmOptions, type: string, user: User, distribution: number[]): Activity {
    const steps = distribution.map(distributionValue => {
        const targetStepTSS = targetTSS * distributionValue;
        console.log(targetStepTSS)
        return evolveStep(targetStepTSS, options, type, user);
    });

    const randomId = `workout_${Date.now() * Math.random() * 1000}`;
    return {
        title: `${type} Activity`,
        id: randomId,
        type: type,
        description: `A VitovaAI generated ${type.toLowerCase()} workout`,
        duration: steps.reduce((sum, step) => sum + (step.stepDistance || 0), 0),
        workout: null,
        steps: steps.filter(s=>s.stepName !== 'Default Step')
    };
}

// Main function to generate an activity with optimized steps
export function updateWorkout(targetTSS: number, options: GeneticAlgorithmOptions, type: string, user: User, distribution: number[]): Activity {
    const steps = distribution.map(distributionValue => {
        const targetStepTSS = targetTSS * distributionValue;
        return evolveStep(targetStepTSS, options, type, user);
    });

    const randomId = `workout_${Date.now() * Math.random() * 1000}`;
    return {
        title: `${type} Activity`,
        id: randomId,
        type: type,
        description: `A VitovaAI generated ${type.toLowerCase()} workout`,
        duration: steps.reduce((sum, step) => sum + (step.stepDistance || 0), 0),
        workout: null,
        steps: steps.filter(s=>s.stepName !== 'Default Step')
    };
}

const generateWeek = (user:User, plan:Plan, activities:Array<string>, numberOfWeeks:number, currentWeekIndex:number) => {
    var days:Array<Day> = [
        {
            day:'Monday',
            activities:[],
        },
        {
            day:'Tuesday',
            activities:[]
        },
        {
            day:'Wednesday',
            activities:[]
        },
        {
            day:'Thursday',
            activities:[]
        },
        {
            day:'Friday',
            activities:[]
        },
        {
            day:'Saturday',
            activities:[]
        },
        {
            day:'Sunday',
            activities:[]
        }
    ]

    for (var day of days){
        // Create a set to keep track of selected indices to ensure uniqueness
        const selectedIndices = new Set();
        const randomNumberOfActivities = Math.floor(Math.random() * activities.length)+1;
        // Call generateAiActivity with the random value
        for (let i = 0; i < randomNumberOfActivities; i++) {
            let randomIndex;
        
            // Ensure we get a unique random index
            do {
                randomIndex = Math.floor(Math.random() * activities.length);
            } while (selectedIndices.has(randomIndex));
        
            // Add the selected index to the set
            selectedIndices.add(randomIndex);
        
            // Select a random value from the array using the unique index
            let randomValue = activities[randomIndex];
            
            const AIActivity = generateAiActivity(user, plan, randomValue, options, currentWeekIndex, days.indexOf(day), activities.length);
            if (AIActivity.steps.length > 0) {
                day.activities.push(AIActivity);
            }
        }
    }

    const week:Week = {
        days: days,
        weekId:`week_${Math.random() * 10000}`,
        weekNumber: `0`,
        weekTitle:``,
        weekDescription:``
    }

    return week;
}

function getNextMonday() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7; // Calculate days until next Monday
    const nextMonday = new Date(now);
    nextMonday.setDate(now.getDate() + daysUntilNextMonday);
    return nextMonday.getTime();
}

const generateAiActivity = (user:User, plan:Plan,type:string, options:any, weekIndex:number, dayIndex:number, numberOfActivities:number) => {
    var distribution = 
        [
            7,
            26,
            42,
            30,
            18,
            7,
            10
        ]
    const sumDistribution = distribution.reduce((partialSum, a) => partialSum + a, 0);
    for (var i = 0; i < distribution.length; i++){
        distribution[i] = distribution[i]/sumDistribution;
    }
    var targetTSS = 0;

    if (plan){
        // Example usage
        const targetWeeklyTrainingLoad = plan.baseLoad + (weekIndex * plan.baseLoad*plan.weeklyAdjustmentValue);

        if (dayIndex >= 0){
            targetTSS = distribution[dayIndex] * targetWeeklyTrainingLoad;
        }
    }
    
    var activity:Activity;
    activity = workoutBuilderGeneticAlgorithmPerStep(targetTSS/numberOfActivities, options, type, user, workoutOptions[type as ActivityType]);
    // for (var j = 0;j<activity.steps.length;j++){
    //     let step = activity.steps[j]
    //     activity.steps[j] = extractActivityStep(step.stepDescription, step.stepName)
    // }
    return activity;
};

export function generateAIPlan(user:User, level: number, activities:Array<string>, numberOfWeeks:number, conditions:Array<CheckedCondition>, medications:Array<CheckedMedication>){
    const randomId = `plan_${Date.now() * Math.random() * 1000}`
    let titleString = `Level ${level} ${activities[0]}`;

    for (const activityType of activities.filter(a=>a!== activities[0])){
        titleString += '/' + activityType
    }

    titleString += ' Plan'
    
    const newPlan:Plan = {
        planId:`${randomId}`,
        title:titleString,
        description:`VitovaAI generated plan suitable for level ${level} athletes.`,
        baseLoad:200 + (200 * level),
        content:[],
        startDate:getNextMonday(),
        condition:'',
        weeklyAdjustmentValue:0.05,
    }

    for (let i = 0; i < numberOfWeeks;i++){
        let weekToAdd = generateWeek(user, newPlan, activities, numberOfWeeks, i);
        newPlan.content.push(weekToAdd)
    }

    return newPlan;
}

export default workoutBuilderGeneticAlgorithmPerStep;
