import React from 'react';
import colorPalette from '../utils/colors';

interface ArticleProps {
    title: string;
    author: string;
    date: Date;
    content: (string | { subheading: string })[]; // Array can now include strings or subheading objects
}

const ArticleComponent: React.FC<ArticleProps> = ({ title, author, date, content }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: '20px',
            height: '80vh',
            width: '80%', 
            transition: 'width 0.3s ease',
            overflowY: 'auto',
            backgroundColor: '#f7f7f7',
            borderLeft: '2px solid #ddd',
        }}>
            <small style={{ marginLeft: '80%' }}>By {author} on {date.toDateString()}</small>
            <div style={{ marginTop: '20px', height: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                {/* Render the title */}
                <h2 style={{fontWeight:'bold', fontStyle:'italic', textAlign:'center',color:colorPalette.steelBlue, fontSize:'3vw'}}>{title}</h2>

                {/* Render each content section (subheading or paragraph) */}
                {content.map((section, index) => 
                    typeof section === 'string' ? (
                        <p key={index}>{section}</p>
                    ) : (
                        <h3 key={index} style={{ fontSize: '1.5em', fontWeight: 'bold', color:'#454545', margin:0}}>
                            {section.subheading}
                        </h3>
                    )
                )}
            </div>
        </div>
    );
};

export default ArticleComponent;
